"use client";

import classNames from "classnames";
import { Colors } from "components/colors";
import { Font } from "components/font";
import { spacing } from "libs/styles/variables";
import { memo, ReactNode } from "react";
import styled, { css } from "styled-components";

type Props = {
  children: ReactNode;
  className?: string;
};

export const H1 = memo(function H1({ children, className }: Props) {
  return <H1Wrapper className={classNames(className)}>{children}</H1Wrapper>;
});

export const H2 = memo(function H2({ children, className }: Props) {
  return <H2Wrapper className={classNames(className)}>{children}</H2Wrapper>;
});

export const H3 = memo(function H1({ children, className }: Props) {
  return <H3Wrapper className={classNames(className)}>{children}</H3Wrapper>;
});

export const H4 = memo(function H4({ children, className }: Props) {
  return <H4Wrapper className={classNames(className)}>{children}</H4Wrapper>;
});

const Base = css`
  margin: 0;
  padding: 0;

  small {
    color: ${Colors.black56};
    font-size: 80%;
    margin-left: ${spacing.xxs};
  }
`;

const H1Wrapper = styled.h1`
  ${Base}
  font-size: ${Font.fontSizeXl};
  font-weight: 500;
`;

const H2Wrapper = styled.h2`
  ${Base}
  font-size: ${Font.fontSizeLg};
  font-weight: 500;
`;

const H3Wrapper = styled.h3`
  ${Base}
  font-size: ${Font.fontSizeMd};
  font-weight: 500;
`;

const H4Wrapper = styled.h4`
  ${Base}
  font-size: ${Font.fontSizeSm};
  font-weight: 400;
`;
