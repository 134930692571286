export const Colors = {
  black04: "rgba(0, 0, 0, 0.04)",
  black12: "rgba(0, 0, 0, 0.12)",
  black38: "rgba(0, 0, 0, 0.38)",
  black56: "rgba(0, 0, 0, 0.56)",
  black87: "rgba(0, 0, 0, 0.87)",

  white04: "rgb(255, 255, 255, 0.4)",
  white56: "rgba(255, 255, 255, 0.56)",
  white87: "rgba(255, 255, 255, 0.87)",
  white100: "#ffffff",

  primaryBase: "#1D2542",
  primaryBaseAlphaDark: "rgba(11, 20, 51, 0.92)",
  primaryBaseAlphaLight: "rgba(29, 37, 66, 0.56)",
  primaryBaseLight: "#ECEFF5",
  primaryBaseLight2: "#ECEFF5", // 後方互換性のためにおいておく

  primary: "#7FA2F5",
  primaryLight1: "#ADC2F6",
  primaryLight2: "#E6EBF8",
  primaryDark1: "#5D8AF5",
  primaryDark2: "#4075F3",

  secondary: "#6ED8D9",
  secondaryLight1: "#9CEBEC",
  secondaryLight2: "#ECFCFC",
  secondaryDark1: "#43CCCD",
  secondaryDark2: "#22BFC0",

  alert: "#EA4F4F",
  alertDark1: "#E63E3E",
  alertDark2: "#D72F2F",
  alertLight1: "#F4A1A1",
  alertLight2: "#FFEAEA",
  shimmerMiddle: "hsl(216, 33%, 93.295%)",
  shimmerLast: "hsl(216, 33%, 92.59%)",
};

export const ColorFilters = {
  black87:
    "alpha(87%) invert(0%) sepia(100%) saturate(0%) hue-rotate(165deg) brightness(95%) contrast(102%)",
  black56:
    "alpha(56%) invert(0%) sepia(100%) saturate(0%) hue-rotate(165deg) brightness(95%) contrast(102%)",
  white:
    "invert(100%) sepia(0%) saturate(0%) hue-rotate(17deg) brightness(104%) contrast(104%)",
  primary:
    "invert(56%) sepia(90%) saturate(465%) hue-rotate(193deg) brightness(98%) contrast(95%)",
  primaryDark1:
    "invert(47%) sepia(66%) saturate(1944%) hue-rotate(204deg) brightness(101%) contrast(92%)",
};
